import React from "react"
import { Link } from "react-router-dom"
// import PropTypes from "prop-types"
// import mainImage from '../images/copy.png';
import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text, AspectRatio,
} from '@chakra-ui/react';

export default function SectionHeader({text, ...rest}) {
    return (
        <Text
            // color="#ffffff"
            color="#ffcd03"
            fontSize={48}
            // ml={{base: "0%", md:'10%', lg: "10%", xl: "10%", }}
            mb={6}
            // alignSelf={{base: "center", md: "start"}}
            alignSelf={{base: "center"}}
            // textShadow="4px 4px 4px black"
            textAlign="center"
            lineHeight={{base: 1.1}}
            {...rest}
        >
            {text}
        </Text>
    );
}