import React from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    // useColorMode, useColorModeValue,
} from '@chakra-ui/react';
import theme from './theme.js';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import Main from './components/main';
import Splotch from './images/R4.png';

function App() {
    // const { colorMode } = useColorMode();
    // const isDarkMode = colorMode === 'dark';

    // const gradient = useColorModeValue('linear-gradient(to right, #00000, #ffffff)', 'linear-gradient(to right, #1b202b, #000000)');

    // Define light and dark gradients
    // const lightGradient = 'linear-gradient(to top right, #00274C, #00274C)';
    // const darkGradient = 'linear-gradient(to right, #1b202b, #000000)';
    // const gradient = isDarkMode ? darkGradient : lightGradient;

    return (
        <ChakraProvider theme={theme}>

        {/*<div*/}
        {/*    style={{*/}
        {/*        minHeight: '100vh',*/}
        {/*        background: gradient,*/}
        {/*        display: 'flex',*/}
        {/*        flexDirection: 'column',*/}
        {/*    }}*/}
        {/*>*/}

            <Box
                w="100vw"
                className="boxWithBackground"
                // backgroundImage={Splotch}
                // backgroundSize="150vw"
                // backgroundPosition="center"
                // // transform="rotate(180deg)"
                // style={{ 'before': {"transform": "rotate(180deg)"} }}
            >
                <Main />

            </Box>

        {/*</div>*/}
        </ChakraProvider>

    );
}

export default App;
