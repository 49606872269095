import React, { useRef, useEffect } from 'react';
import About from './about';
import Join from './join';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeroSection from './herosection';
import Navbar from './navbar';
import { Box, Flex, Image } from '@chakra-ui/react';
import Home from './home';
import WhoWeAre from './whoweare';
import Splotch from '../images/R4.png';
import Footer from './footer';

// const styles = {
//     body: {
//         margin: 0,
//         position: 'relative',
//         overflow: 'hidden',
//     },
//     backgroundImage: {
//         backgroundImage: `url(${Splotch})`,
//         backgroundRepeat: 'repeat',
//         backgroundSize: 'cover', // Adjust as needed
//         width: '100%', // Set the width of the background container
//         height: '100%', // Set the height of the background container
//         position: 'absolute',
//         top: 0,
//         left: 0,
//     },
// };

export default function Main() {

    return (
        <>
            <Navbar/>

            <Flex
                align="center"
                justify={{ base: 'center', }}
                direction={{ base: 'column' }}
                wrap="no-wrap"
                minH="70vh"
                px={{base: 4, md: 8}}
                // mb={16}
                mx="auto"
                maxW={{base: "600px", md: "1500px"}}
                // alignItems="center"
                // alignContent="center"
                // backgroundImage={Splotch}
                // backgroundSize="120vw"
                // backgroundPosition="center"
            >

                {/*<Image src={Splotch}*/}
                {/*       w="120vw"*/}
                {/*       position="absolute" // Set absolute positioning on the Image*/}
                {/*       top="0" // Adjust positioning as needed*/}
                {/*       left="0" // Adjust positioning as needed*/}
                {/*       zIndex={-900}*/}
                {/*       // maxH="5000px"*/}
                {/*       objectFit="cover"*/}
                {/*/>*/}

                {/*<Box style={styles.backgroundImage} zIndex={-90}/>*/}

                <HeroSection/>

                <Home/>

                <WhoWeAre/>

                <Join/>

                <Footer/>

            </Flex>

        </>

    );
}



