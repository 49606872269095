import { Text } from '@chakra-ui/react';

export default function Footer() {
    return (
        <Text
            mb={6}
            textAlign="center"
            fontSize={16}
        >
            {"\u00A9"} 2023 - 2024 Michigan Computer Graphics | Made by Derek Goh
        </Text>
    );
}