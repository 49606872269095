import React from "react"
import { Link } from "react-router-dom"
// import PropTypes from "prop-types"
// import mainImage from '../images/copy.png';
import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text, AspectRatio, VStack, SimpleGrid, UnorderedList, ListItem,
} from '@chakra-ui/react';
import SectionHeader from './sectionheader';

import WhyCg1 from "../images/home/whycg1.png";
import WhyCg2 from "../images/home/whycg2.png";
import WhyCg3 from "../images/home/whycg3.jpg";
import WhyCg4 from "../images/home/whycg4.png";
import WhyCg5 from "../images/home/whycg5.png";
import WhyCg6 from "../images/home/whycg6.jpg";

const whyCG = [
    {"title": "It's Everywhere", "images": [WhyCg1, WhyCg2], "urls": ["https://www.cyberpunk.net/", "https://www.pixelcraft.work/"], "description": "CG is becoming crucial in many industries"},
    {"title": "It's Challenging", "images": [WhyCg3, WhyCg4], "urls": ["http://15462.courses.cs.cmu.edu/fall2019/lecture/renderingequation/slide_006", "https://research.nvidia.com/publication/2021-06_restir-gi-path-resampling-real-time-path-tracing"], "description": "As a multi-disciplinary field, CG is a rewarding effort"},
    {"title": "It's Cool", "images": [WhyCg5, WhyCg6], "urls": ["https://github.com/palle-k/Mandelbrot", "https://www.artstation.com/artwork/GPWK3"], "description": "The possibilities with CG are mind-blowing and endless"}
];

import WhatCg from "../images/home/whatcg.png";

import HowCg1 from "../images/home/howcg1.png";
import HowCg2 from "../images/home/howcg2.png";
import HowCg3 from "../images/home/howcg3.jpg";

const mcgData = [
    {"title": "Topics", "bullets": ["CG Basics", "Blender", "Ray Tracing", "Gen AI", "And More!"]},
    {"title": "Activities", "bullets": ["In-depth Lectures", "Project Experience", "Guest Speakers"]},
    {"title": "Why Join", "bullets": ["Main CG Presence at UofM", "Smaller Club \u2192 Greater Impact", "Low Commitment"]},
];

export default function Home() {
    return (

        <>
            <SectionHeader text="Why Computer Graphics"/>


            <VStack
                    // w={{lg: "40%"}}
                    // alignItems="left"
                    // border="10px solid red"
                mb={16}
            >

                {
                    whyCG.map((reason, index) => (
                        <>
                            <Text
                                // color="#ffcd03"
                                fontWeight="bold"
                                // display="inline"
                                fontSize={22}
                                mb={4}
                                // border="10px solid red"
                                textAlign="center"
                            >

                                {reason.title}

                                <Text
                                    fontWeight="normal"
                                    fontSize={22}
                                    // display="inline"
                                >
                                    {reason.description}
                                </Text>
                            </Text>

                            <Stack
                                direction={{base: "column", lg: "row"}}
                                spacing={8}
                                mb={16}
                                w="100%"
                                mx="auto"
                                justify="center"
                                alignItems="center"
                                // border="10px solid red"
                            >
                                {
                                    reason.images.map((picture, picIndex) => (
                                        <a
                                            key={picIndex}
                                            href={reason.urls[picIndex]}
                                            style={{ display: "block"}}
                                            target={'_blank'}
                                        >
                                            <Image
                                                // key={picIndex}
                                                src={picture}
                                                w={{base: "100%", lg: "100%"}}
                                                maxW={{md: "500px", lg: "450px"}}
                                                shadow="dark-lg"

                                                _hover={{transform: "scale(1.05)"}}
                                                transition="transform 0.15s cubic-bezier(0.4, 0, 0.2, 1)"

                                                // border="15px solid #00274C"
                                                rounded="1rem"
                                            />
                                        </a>
                                    ))
                                }

                                {/*/!*<Box*!/*/}
                                {/*/!*    boxShadow="8px 8px 12px #00101e, -8px -8px 12px #003e7a"*!/*/}
                                {/*/!*    rounded="1rem"*!/*/}
                                {/*/!*    p={4}*!/*/}
                                {/*/!*>*!/*/}
                                {/*    <Image*/}
                                {/*        src={reason.images[0]}*/}
                                {/*        w={{base: "100%", lg: "40%"}}*/}
                                {/*        maxW={{md: "500px"}}*/}
                                {/*        shadow="dark-lg"*/}

                                {/*        // border="15px solid #00274C"*/}
                                {/*        rounded="1rem"*/}
                                {/*    />*/}
                                {/*/!*</Box>*!/*/}


                                {/*<Image*/}
                                {/*    src={reason.images[1]}*/}
                                {/*    w={{base: "100%", lg: "40%"}}*/}
                                {/*    maxW={{md: "500px"}}*/}
                                {/*    // boxShadow="8px 8px 12px #00101e, -8px -8px 12px #003e7a"*/}
                                {/*    // border="15px solid #00274C"*/}
                                {/*    shadow="dark-lg"*/}
                                {/*    rounded="1rem"*/}
                                {/*/>*/}
                            </Stack>
                        </>
                    ))
                }

            </VStack>



            <SectionHeader text="About MCG" id="about" scrollMarginTop="95px"/>

            <Text
                mb={12}
                fontSize={22}
                textAlign="center"
                w={{md: "80%", lg: "100%"}}
            >
                Our mission is to create an engaging platform to explore, learn, and engage with the various disciplines of computer graphics.
            </Text>

            <SimpleGrid
                columns={{base: 1, md: 2, lg: 3}}
                spacing={{base: 8, md: 12 }}
                mb={32}
            >

                {
                    mcgData.map((topic, index) => (
                        <Box
                            // border="10px solid red"
                            maxW={{md: "300px", lg: "300px"}}
                            // ml={{ lg: index === mcgData.length - 1 ? 12 : 0}}
                            p={6}
                            // alignItems="start"
                            boxShadow="dark-lg"
                            // boxShadow="8px 8px 16px #00101e, -1px -1px 24px #001a30"
                            rounded="1rem"
                            // bg="rgba(0, 39, 76, 0.4)"
                        >
                            <Text
                                fontWeight="bold"
                                fontSize={22}
                                // color="#ffcd03"
                                // textAlign="center"
                            >
                                {topic.title}
                            </Text>

                            <Text
                                fontSize={22}
                                // color="#ffcd03"
                            >

                                <UnorderedList>
                                    {
                                        topic.bullets.map((point) => (
                                            <ListItem>{point}</ListItem>
                                        ))
                                    }
                                </UnorderedList>
                            </Text>
                        </Box>
                    ))
                }

            </SimpleGrid>

        </>
    )
}

