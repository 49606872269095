import React from "react"
import { Link } from "react-router-dom"

import {
    Box,
    Button,
    Flex,
    Image,
    Heading,
    Stack,
    Text,
    AspectRatio,
    useBreakpointValue
} from '@chakra-ui/react';

import UseDeviceSize from './usedevicesize';

export default function HeroSection() {

    const [width, height] = UseDeviceSize();
    const smallScreenFontSize = Math.min(0.145 * width, 80);

    return (

        <Box
            mb={60}
            id="home"
            scrollMarginTop="200px"
            zIndex={950}
            // border="10px solid red"
            // w="100vw"
            // h={{sm: '100vw', md: "80vw", lg: "60vw" }}
            // pt={8}
            mt={{base: '10vh', md: "10vw", xl: "128px"}}
        >
            <Text
                // fontWeight="bold"
                fontSize={{base: `${smallScreenFontSize}px`, md: "90px", lg: "100px" }}
                lineHeight="1.1"
                // fontFamily="montserrat"
                // textShadow="'0 0 5px #000'"
                fontWeight="thin"
                // shadow="2xl"
                // p={4}
                // textShadow="8px 8px black"
                // textShadow="2px 2px 5px white"
                textShadow={{base: "4px 4px 4px #000000", md: "12px 12px 8px black" }}
                mt={20}
                mb={8}
                textAlign="center"
            >
                MICHIGAN
                <Text
                    fontWeight="extrabold"
                    color="#ffcd03"
                    // bg="radial-gradient(circle, rgba(255, 36, 98, 0.8) 0%, rgba(255, 36, 98, 0.2) 100%)"
                    // shadow="2xl"
                    // textShadow="5px 5px #ffa600"
                    // textShadow="12px 12px 8px black"
                >
                    COMPUTER <br />
                    GRAPHICS
                </Text>
            </Text>

            <Text
                fontSize={22}
                textAlign="center"
            >
                University of Michigan's Premier Computer Graphics Club
            </Text>

        </Box>
    )
}

