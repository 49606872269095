import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    config: {
        initialColorMode: "dark",
        useSystemColorMode: false,
    },
    styles: {
        global: {
            body: {
                bg: "#00274C", // Your desired background color
                fontFamily: "Montserrat, sans-serif"
            },
        },
    },
    fonts: {
        body: 'Montserrat, sans-serif',
        heading: 'Montserrat, sans-serif',
    },
    components: {
        Text: {
            baseStyle: {
                maxW: '800px', // Set the maximum width for all Text components
            },
        },
    },
});

export default theme;