import React from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Flex,
    Text,
    Button,
    Stack,
    // useColorModeValue,
    Image,
    Menu,
    MenuButton,
    MenuList,
    IconButton, MenuItem,
} from '@chakra-ui/react';

import {
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'

import { ColorModeSwitcher } from '../ColorModeSwitcher';

import { HamburgerIcon } from '@chakra-ui/icons';
import {
    BsHouse,
    BsHouseFill,
    BsGiftFill,
    BsFillInfoCircleFill,
    BsPerson,
    BsPersonFill,
    BsArrowRight,
} from 'react-icons/bs';

const navbarFontSize = 18;

const data = [
    {"name": "Home", "url": "#home", "icon": BsHouseFill},
    {"name": "About", "url": "#about", "icon": BsFillInfoCircleFill},
    {"name": "Our Team", "url": "#ourteam", "icon": BsPersonFill},
    {"name": "Join", "url": "#join", "icon": BsArrowRight},
];

// const scrollToSection = (sectionId, event) => {
//     event.preventDefault();
//     console.log('Scrolling to section:', sectionId);
//
//     const section = document.getElementById(sectionId);
//     const navbarHeight = document.getElementById('navbar').offsetHeight;
//
//     if (section) {
//         console.log('Section found:', section);
//
//         section.scrollIntoView({
//             behavior: 'smooth',
//             block: 'start',
//             inline: 'nearest',
//             offsetTop: -navbarHeight,
//         });
//     } else {
//         console.log('Section not found');
//     }
//
//     console.log('Document:', document);
// };

const CustomMenuItem = (props) => {
    const { children, isLast, to = '/', ...rest } = props;
    return (
        <a href={to}>
            <Text
                mb={{ base: isLast ? 0 : 8, sm: 0 }}
                mr={{ base: 0, sm: isLast ? 0 : 8 }}
                display="block"
                fontWeight="semibold"
                fontSize={navbarFontSize}
                // textShadow="5px 5px 5px black"
                fontFamily="montserrat"
                transition="color 0.15s ease"
                _hover={{color: "rgba(125, 125, 125, 1)"}}
                {...rest}
            >
                {children}
                {/*<Link to={to}>{children}</Link>*/}
            </Text>
        </a>
    );
};

export default function Navbar(props) {
    const [show, setShow] = React.useState(false);
    const toggleMenu = () => setShow(!show);
    // const bgColor = useColorModeValue("white", "#1a202c");
    const bgColor = "#00274C"
    // const gradient = useColorModeValue('linear(to-r, #1b202b, #ffff)', 'linear(to-r, #1b202b, #000000)');
    // const gradient = 'linear-gradient(to right, indigo, #00274C)';



    return (

        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            minH="75px"
            // mb={8}
            p={4}
            pt={4}
            pb={4}
            position="sticky"
            top="0"
            zIndex="999"
            id="navbar"

            // TODO: Uncomment these 2 lines and comment out lines
            // TODO: 88 and 89 for opaque and shadow
            // bg={bgColor}
            shadow="2xl"

            // TODO: Uncomment these 2 lines and comment out lines
            // TODO: 83 and 84 for translucent and no shadow
            bg="rgba(0, 39, 76, 0.2)"
            backdropFilter="blur(12px)"

            // bgGradient={gradient}
            // bg={["primary.500"]}
            // color={["white", "white", "primary.700", "primary.700"]}
            {...props}
        >
            <Flex align="center">

                <Box display={{ base: 'block' }}>
                    {/*<Link to="/">*/}

                    <a href="#home">
                        <Text
                            fontWeight='bold'
                            fontSize='48px'
                            lineHeight='0'
                            color='#ffcd03'
                            // textShadow="4px 4px #ffa600"
                            textShadow='4px 4px 4px #000000'
                        >
                            <Text
                                color='#ffffff'
                                display='inline'
                                fontWeight='normal'
                            >
                                M
                            </Text>

                            CG
                        </Text>

                    </a>

                        {/*<Image*/}
                        {/*    src={Logo}*/}
                        {/*    w="100px"*/}
                        {/*    color={['white', 'white', 'primary.500', 'primary.500']}*/}
                        {/*/>*/}
                        {/*</Link>*/}
                </Box>

            </Flex>

            <Box display={{ base: "block", md: "None" }}>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon w={8} h={8}/>}
                        bg="transparent"
                        color="#ffffff"
                        // variant='outline'
                    />
                    <MenuList
                        // bg="#101010"
                        bg="#00274C"
                    >

                        {
                            data.map((item, index) =>
                                // <Link key={index} to={item.url} style={{ textDecoration: 'none' }}>

                                <a href={item.url} key={index}>
                                    <MenuItem
                                        // bg="#101010"
                                        bg="#00274C"
                                        icon={<Box as={item.icon} w={6} h={6}/>}
                                        to={item.url}
                                        // ml={4}
                                        // p={2}
                                        // fontFamily="oswald"
                                        fontSize={navbarFontSize}
                                        // fontWeight="semibold"
                                    >
                                        {item.name}
                                    </MenuItem>

                                    {index === data.length - 1 ? <></> : <MenuDivider />}


                                </a>

                                // </Link>
                            )
                        }

                    </MenuList>
                </Menu>

            </Box>


            <Box
                display={{ base: show ? 'block' : 'none', md: 'block' }}
                flexBasis={{ base: '100%', md: 'auto' }}
            >
                <Flex
                    align={['center', 'center', 'center', 'center']}
                    justify={['center', 'space-between', 'flex-end', 'flex-end']}
                    direction={['column', 'row', 'row', 'row']}
                    pt={[4, 4, 0, 0]}
                >

                    {
                        data.slice(0, data.length - 1).map((item, index) =>
                            <CustomMenuItem isLast={index === data.length - 2} to={item.url} >{item.name}</CustomMenuItem>
                        )
                    }

                    <a href={data[data.length - 1].url}>
                        <Button ml={6} fontSize={navbarFontSize}>Join</Button>
                    </a>

                    {/*<ColorModeSwitcher/>*/}

                </Flex>
            </Box>
        </Flex>
    );
};