import React from "react";
import {
    Box,
    Flex,
    Image,
    Heading,
    Text,
    Stack,
    Card,
    CardBody,
    CardFooter,
    Button,
    Divider,
    ButtonGroup, SimpleGrid, VStack,
} from '@chakra-ui/react';
// import mainImage from '../copy.png';
import gary from "../images/gary.jpg";
import surya from "../images/surya.jpg";
import aidan from "../images/AidanMCGphotoSmall.jpg";
import angelina from "../images/angelina.jpg";
import anthony from "../images/anthony.jpg";
import derek from "../images/derek3.jpeg";
import SectionHeader from './sectionheader';

const leaders = [
    {"name": "Gary Huang", "position": "President", "image": gary, "description": "Gary is a fourth year CS student. He likes squirrels, spades & poker, and is an AMD fan. He is also an EECS 482 IA, and his favorite class is EECS 470."},
    {"name": "Surya Bala", "position": "Former VP", "image": surya, "description": "Surya is a CS alumnus with interests in robotics and entrepreneurship. He enjoys music production as a hobby. His favorite class is MO 302."},
    {"name": "Aidan Donley", "position": "Vice President", "image": aidan, "description": "Aidan is a third year CS student with interests in computer networking, materials science, and card games. He focuses on rasterization based computer graphics and his favorite class is MSE 440."},
    {"name": "Angelina Zhang", "position": "Treasurer", "image": angelina, "description": "Angelina is a fourth year CS student, and she enjoys playing Wingspan, Crew, and Tractor. Her favorite class is Math 297."},
    {"name": "Anthony Tan", "position": "Secretary", "image": anthony, "description": "Anthony is a fourth year CS student. His favorite board game is Cryptid, and he enjoys animating. His favorite class is EECS 370."},
    {"name": "Derek Goh", "position": "Webmaster", "image": derek, "description": "Derek is a fourth year CS student whose interests include gardening, astronomy, severe thunderstorms, and smart home technology. His favorite class is CHEM 130."},
];

export default function WhoWeAre() {
    return (
        <>
            <SectionHeader text="Who We Are" id="ourteam" scrollMarginTop="95px"/>

            <SimpleGrid
                spacing={{base: 8, md: 12 }}
                columns={{base: 1, md: 2, lg: 3}}
                justify="center"
                // p={16}
                // pb={-10}
                mb={32}
            >

                {
                    leaders.map((person, index) => (

                    <Box
                        key={index}
                        // border="10px solid red"
                        // maxW={{md: "400px"}}
                        padding={6}
                        shadow="dark-lg"
                        // boxShadow="8px 8px 12px #00101e, -2px -2px 12px #00101e"
                        // bg="#ffcd03"
                        // bg="#2156b9"
                        // border="5px solid #2156b9"
                        rounded="2rem"
                        maxW={{base: "500px", md: '300px', lg: "300px", xl: "300px" }}
                    >
                        <Stack
                            direction={{base: "column", md: "column"}}
                        >
                            <Image
                                src={person.image}
                                w={{base: "100%", }}
                                minW={{base: "200px"}}
                                // h="300px"
                                // maxW={{base: "unset", md: "100px"}}
                                rounded="1rem"
                                shadow="2xl"
                                // boxShadow="8px 8px 12px #00101e"
                                // aspectRatio={1}
                                // align="center"
                                mb={2}
                            />

                            <VStack>
                                <Text
                                    fontWeight="bold"
                                    fontSize={22}
                                    lineHeight={1}
                                >
                                    {person.name}
                                </Text>

                                <Text
                                    fontWeight="bold"
                                    fontSize={22}
                                    color="#ffcd03"
                                    lineHeight={1}
                                    mb={2}
                                >
                                    {person.position}
                                </Text>

                                <Text
                                    // fontSize={22}
                                    // color="#ffcd03"
                                >
                                    {person.description}
                                </Text>

                            </VStack>

                        </Stack>

                    </Box>

                ))}

            </SimpleGrid>
        </>
    );
}
