import React, { useRef, useEffect, useState } from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    theme, Image, SimpleGrid,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import Navbar from './navbar';
import SectionHeader from './sectionheader';

import DiscordLogo from '../images/discord.png';
import { BsListCheck } from 'react-icons/all';
import GmailLogo from '../images/gmail.png';



export default function Join() {

    const iconData = [
        { 'name': 'Discord', 'icon': DiscordLogo, 'isImage': true, url: 'https://discord.gg/sDYaBprFFu'},
        {
            'name': (
                <Text>Form</Text>
            ),
            'icon': BsListCheck,
            'isImage': false,
            url: 'https://docs.google.com/forms/d/e/1FAIpQLSd-bvzqKOqTzuObqyykF7fBOSrVykHwhkEBK0I9VKVYXJ0gXg/viewform?usp=sf_link'
        },
        { 'name': 'Email', 'icon': GmailLogo, 'isImage': true, url: 'mailto:mcg-leadership@umich.edu'},
    ];

    return (

        <>
            <SectionHeader text='Join' id="join" scrollMarginTop="95px"/>

            <Text
                mb={16}
                fontSize={22}
                // textAlign='center'
                // color='#ffcd03'
                w={{md: "80%", lg: "100%"}}
                // maxW={{md: "750px"}}
                textAlign={{base: "center", md: "left" }}
            >
                Our Discord server is the primary hub for the MCG community, but feel free to
                reach out to us at {" "}

                <a href="mailto:mcg-leadership@umich.edu" style={{textDecoration: "underline"}}>
                    mcg-leadership@umich.edu
                </a>.

                We usually meet on Wednesdays from 6 to 7 PM on North Campus. Use the
                interest form below to add yourself to the mailing list and receive updates!

            </Text>

            <SimpleGrid
                columns={{ base: 2, md: 3 }}
                spacing={{base: 16, md: 24 }}
                mb={48}
            >
                {
                    iconData.map((item, index) => (
                        <a
                            href={item.url}
                            target={index === 2 ? '_self' : '_blank'}
                            key={index}
                        >
                            <Box
                                p={4}
                                shadow='dark-lg'
                                // boxShadow="8px 8px 12px #00101e, -8px -8px 12px #003e7a"
                                rounded='1rem'
                                // justify="center"
                                _hover={{bg: "rgba(0, 0, 0, 0.2)"}}
                                // transition="transform 0.15s cubic-bezier(0.4, 0, 0.2, 1)"
                                transition="background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1)"
                            >
                                {
                                    item.isImage ? (
                                        <>
                                            <Image src={item.icon} w='60px' mx='auto' mb={4} />
                                        </>
                                    ) : (
                                        <>
                                            <Box as={item.icon} w='60px' h='60px' mx='auto' mb={0.5}/>
                                        </>
                                    )
                                }


                                <Text textAlign='center' fontSize={22} lineHeight={1}>{item.name}</Text>
                            </Box>
                        </a>
                    ))
                }

            </SimpleGrid>
        </>
    );
}